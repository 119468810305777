import * as React from 'react'
import Provider from './provider'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from './src/theme/global-style'

export const wrapRootElement = ({ element }) => (
   <Provider>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
   </Provider>
)

export const wrapPageElement = ({ element }) => (
   <>
      <GlobalStyle />
      {element}
   </>
)
