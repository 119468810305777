import React, { useState } from 'react'

export const myContext = React.createContext()

const Provider = (props) => {
   const [menu, setMenu] = useState({
      data: null,
      type: 'desktop',
      source: null,
   })
   const [isCtaOpen, setCtaOpen] = useState(true)
   const changeMenu = (value) => setMenu(value)
   const closeCta = () => setCtaOpen(false)

   return (
      <myContext.Provider
         value={{
            menu,
            changeMenu,
            isCtaOpen,
            closeCta,
         }}
      >
         {props.children}
      </myContext.Provider>
   )
}
export default Provider
