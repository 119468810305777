import { createGlobalStyle } from 'styled-components'
import { reset } from 'styled-reset'
import './fonts/fonts.css'

export const theme = {
   font: 'bould',
   color: {
      primary: '#F58D77',
      primaryLight: '#FDD8CC',
      gray: '#D0CCCA',
      grayLight: '#F5F4F3',
      grayExtraLight: 'rgba(245, 244, 243, 0.4)',
      black: '#243433',
      blackLight: '#656F6E',
      red: '#F06767',
      redLight: '#F9DEDC',
      blue: '#6BADF3',
      // blueLight: 'rgba(224, 240, 254, 0.7)',
      blueLight: '#D5EBFE',
      white: '#FFFDFD',
   },
   fontSize: {
      normal: '1rem',
      h1: '3.5rem',
      h2: '3rem',
      h3: '1.5rem',
      h4: `1.25rem`,
   },
   shadow1: {
      normal:
         '3px 8px 15px rgba(230, 166, 145, 0.15), 1px 1px 5px rgba(235, 188, 161, 0.12)',
      hover: '6px 15px 20px rgba(230, 166, 145, 0.25), 3px 1px 20px rgba(235, 188, 161, 0.2)',
   },
   transition: 'all .2s ease-in-out',
   breakpoint: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
      xxl: '1400px',
   },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    font-size: 16px;
  }
  html, body{
    font-family: ${({ theme }) => theme.font};
    font-weight: 400;
    line-height: 1.5;
    height:100%;
  width:100%;
  color: ${({ theme }) => theme.color.black};
  }
  h1, h2, h3, h4, h5, h6{
    line-height: 1.3;
    font-weight: 500;
  }
  h1, h2 {
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 2rem !important;
   }
  }
  button, a, input[type="submit"]{
    font-family: ${({ theme }) => theme.font};
     border: none;
     outline: none;
     cursor: pointer;
   }
   a{
     color: ${({ theme }) => theme.color.primary};
     text-decoration: none;
     transition: ${({ theme }) => theme.transition};
     font-weight: 500;
   }
   svg.drop-shadow{
     overflow: visible;
   }
   .drop-shadow{
     filter: drop-shadow(3px 8px 15px rgba(230, 166, 145, 0.15)) drop-shadow(1px 1px 5px rgba(235, 188, 161, 0.12));
   }
   .hide-lg{
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display:none;
   }
  }
   .show-lg{
    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      display:none;
   }
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display:block;
   }
   }
   .scroll{
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 24px;
    }

    &::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: ${({ theme }) => theme.color.grayLight};
    }
   }
`
